
import { HabilitationStatusName } from "../enum/habilitationStatusName"
import { UserStatus } from "../enum/userStatus"
import { Habilitation } from "../habilitation/habilitation"
import { HabilitationName } from "../enum/habilitation.enum"
import { TaleezRole } from "../taleez/taleezRole.enum"
import { Structure } from "../taleez/structure.model"

export class User {

    id: string
    realm: string
    firstName: string
    lastName: string
    email: string
    organization: Organization
    habilitations: Habilitation[]
    cguValidated: boolean
    firstConnection: boolean
    showContact: boolean
    status: UserStatus
    memberApplication: MemberApplication
    level: UserLevel
    cguValidations: CguValidation[]
    creationDate: Date
    lastConnection: Date
    departureReason?: string
    taleezRole?:TaleezRole
    taleezStructure?:number

    hasAcceptedHabilitation(habilitation: string): boolean{

        return this.habilitations.find( hab => hab.habilitationStrapiCopy.name == habilitation && hab.status == HabilitationStatusName.ACCEPTE) !=null;
    }

    getHabilitationStatus(name: string): HabilitationStatusName{
        let habilitation  = this.habilitations.find(hab => hab.habilitationStrapiCopy.name == name);
        return habilitation ? habilitation.status : null;
    }

}

export class Organization {
    id: string
    name: string
    type: string
}


export class MemberApplication{
    idRequest: number;
    isPui: boolean;
    pui: string;
    otherStructureName: string;
    otherStructureType: string;
    comment: string;
    organization: Organization;
}



export enum UserLevel {   
    ADMIN="ADMIN",
    SUPER_ADMIN="SUPER_ADMIN"
}

export class CguValidation{
    id: number
    validationDate: Date
    cgu: Cgu
}

export class Cgu{
    id: number
    version: string
    insertionDate: Date
}